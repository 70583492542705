<template>
  <header ref="headerRef" class="pointer-events-none fixed left-0 top-0 z-10 w-full py-4 lg:py-8">
    <div class="container relative z-10 flex items-center justify-between">
      <div class="pointer-events-auto lg:py-2">
        <Logo :isFullLogo="isHeroVisible" />
      </div>
      <slot />
      <div class="pointer-events-auto lg:py-2">
        <ButtonVideo
          :isVideoVisible="isHeroVisible"
          :to="'/contact'"
          label="Contact us"
          class="absolute right-0 top-0"
        />
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
const headerRef = ref<HTMLElement | null>(null)
const isHeroVisible = ref(false)

const { y: scrollY } = useScroll(process.client ? window : null, {
  onScroll: () => {
    if (headerRef.value === null) {
      return
    }

    if (scrollY.value > window.innerHeight) {
      isHeroVisible.value = true
    } else {
      isHeroVisible.value = false
    }
  }
})
</script>
