<template>
  <div class="relative overflow-hidden">
    <div>
      <Transition name="quickFade">
        <Header v-if="store.isHeaderVisible" />
      </Transition>
      <main>
        <slot />
      </main>
      <Transition name="quickFade">
        <Footer v-if="store.isHeaderVisible" :data="store" />
      </Transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from '~/stores/app'

import locales from '~/i18n/locales'

const { locale } = useI18n()
const langAttr = computed(() => locales.find((l) => l.code === locale.value).code)
const store = useAppStore()

useHead({
  htmlAttrs: {
    lang: langAttr
  }
})

onMounted(() => {
  useSmoothScroll()
})
</script>

<style lang="postcss">
html.lenis,
html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}
</style>
