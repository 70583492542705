<template>
  <footer class="relative">
    <div class="grid-default container py-5 text-cyan lg:py-24">
      <div class="col-span-full lg:col-span-4 lg:col-start-2">
        <div class="flex w-full items-center gap-2">
          <small class="whitespace-nowrap text-xs"
            >© {{ $t('footer.copyright') }} {{ year }}
          </small>
          <FooterLinks :data="data.allFooterLinks" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { AppState } from '~/stores/app'

defineProps({
  data: {
    type: Object as PropType<AppState>,
    default: () => {}
  }
})

const year: number = new Date().getFullYear()
</script>
