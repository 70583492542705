<template>
  <div
    class="relative block h-[31px] w-[123px] transform-gpu rounded-full bg-cta bg-[length:123px_31px] bg-top shadow-[0px_0px_22px_#12E2FF] transition duration-300 hover:shadow-[0px_0px_44px_#12E2FF]"
  >
    <span
      ref="pinRef"
      class="absolute -top-[3px] right-[106px] block h-10 w-[54px] origin-right scale-0 cursor-pointer"
      @click="onClick"
    >
      <span class="pin absolute -left-[54px] -top-[54px] block h-[144px] w-[144px]"></span>
      <img
        :src="videoHero"
        class="ratio-square absolute -left-px -top-px h-[38px] w-[38px] transform-gpu rounded-full object-cover transition duration-300 hover:opacity-50"
      />
      <!-- <video
        ref="videoRef"
        class="ratio-square absolute -left-px -top-px h-[38px] w-[38px] rounded-full object-cover"
        muted
        autoplay
        loop
        playsinline
        :width="38"
        :height="38"
      >
        <source :src="videoHero" type="video/mp4" />
      </video>
      -->
    </span>
    <NuxtLink :to="to" class="inline-block py-1.5 pl-8 pr-4 text-sm font-semibold text-cyan">
      {{ label }}
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import videoHero from '~/assets/videos/small2.gif'
const { showVideo } = useModal()

interface Props {
  disabled?: boolean
  label?: string | null
  to: string
  isVideoVisible: boolean
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  isVideoVisible: false
})

// eslint-disable-next-line func-call-spacing
const emit = defineEmits<{
  (event: 'click', e: MouseEvent): void
}>()

function onClick(event: MouseEvent) {
  if (props.disabled) {
    event.preventDefault()
    event.stopPropagation()
  }
  emit('click', event)
  showVideo()
}

const pinRef = ref<HTMLElement | null>(null)
const videoRef = ref<HTMLVideoElement | null>(null)
const tween = ref<null | any>(null)

onMounted(() => {
  nextTick(() => {
    if (pinRef.value) {
      gsap.set([pinRef.value], { scale: 0 })
      tween.value = gsap
        .from([pinRef.value], {
          scale: 1,
          paused: true,
          duration: 0.8,
          ease: 'back.inOut(1.6)'
        })
        .progress(1)
    }
  })
})

watch(
  () => props.isVideoVisible,
  (value) => {
    if (value) {
      tween.value.reverse()
      videoRef.value?.play()
    } else {
      tween.value.play()
      videoRef.value?.pause()
    }
  }
)

onUnmounted(() => {
  if (tween.value) {
    tween.value.kill()
  }
})
</script>

<style lang="postcss" scoped>
.pin {
  background: url('~/assets/images/ui/cta-pin@2x.webp') 0 0 / 144px 144px no-repeat;
}
</style>
