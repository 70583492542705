<template>
  <nav class="pointer-events-auto flex gap-2">
    <NuxtLink
      v-for="item in data"
      :key="item.id"
      :to="
        item.reference
          ? (localePathByType(
              item!.reference._modelApiKey,
              item.reference.slug as string
            ) as string)
          : (item.externalLink as string)
      "
      :target="item.externalLink && '_blank'"
      class="my-1 block w-fit text-xs underline hover:no-underline lg:m-0 lg:ml-1 lg:inline-block"
      noPrefetch
    >
      {{ item.title }}
    </NuxtLink>
  </nav>
</template>

<script setup lang="ts">
import type { FooterLinkRecord } from '~/types/datocms'

const { localePathByType } = useLocalePathByType()

defineProps({
  data: {
    type: Array as PropType<FooterLinkRecord[]>
  }
})
</script>
