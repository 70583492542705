import Lenis from 'lenis'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useAppStore } from '~/stores/app'

export default function useSmoothScroll() {
  gsap.registerPlugin(ScrollTrigger)
  const store = useAppStore()
  const { isMobile } = useDevice()

  const lenis = new Lenis({
    duration: isMobile ? 1 : 1.5,
    easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
    syncTouch: true,
    touchInertiaMultiplier: 5,
    syncTouchLerp: 0.05,
    touchMultiplier: 2
  })

  // Override syncTouch behavior for sliders
  const handleTouchStart = (e) => {
    const targetElement = e.target.closest('.slider') // Replace with your slider class or identifier
    if (targetElement) {
      lenis.options.syncTouch = false // Temporarily disable syncTouch for sliders
    } else {
      lenis.options.syncTouch = true // Re-enable syncTouch for other elements
    }
  }

  document.addEventListener('touchstart', handleTouchStart)

  gsap.ticker.add((time) => {
    lenis.raf(time * 1000)
  })

  store.heroSection = document.getElementById('hero')
  store.packagesSection = document.getElementById('packages')
  store.contactSection = document.getElementById('contact')

  lenis.on('scroll', () => {
    useAlignScrollableElements()
    ScrollTrigger.update()
  })

  useAlignScrollableElements()

  store.lenis = lenis
  // gsap.ticker.lagSmoothing(0)
}
